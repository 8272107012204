/* eslint-disable */
import React from 'react';

import AmazonModal from "./../layouts/images/amazon-nobg.svg";
import WalmartModal from "./../layouts/images/Walmart_logo.svg";
import AvailableAmazon from "./../layouts/images/availableamazon.svg";

import sendEvent from '../helpers/sendAnalytics';

class BuyOnline extends React.Component {
  render() {
    return (
      <div>
        <div className="text-left-and-image-right-centered not-ultra-plush" id="buyonline">
          <div className="text-left-and-image-right-centered-wrapper">

            <div className="text-left-and-image-right-centered-text">
              <div className="intro-light font-38 dark-grey-text" dangerouslySetInnerHTML={{__html: this.props.data.field_buy_online_header.value }}></div>
              <div className="medium-text dark-grey-text small-margin" dangerouslySetInnerHTML={{__html: this.props.data.field_buy_online_copy.value }}></div>
              <a target="_blank" href="https://www.amazon.com/stores/page/1BCE9007-6224-4237-892B-FE495BD663A6" className="button transparent blue-outline ultra-plush">{this.props.data.field_buy_online_link.title}</a>
          </div>

            <div className="text-left-and-image-right-centered-image">
              <div className="retailer-options">

                <div className="retailer-item">
                  <div className="retailer-item_image" />
                  <a className="button blue-outline" onClick={() => sendEvent({
                    ga: {
                      category: 'Buy Online',
                      action: 'Click',
                      label: 'Amazon_CTA'
                    }
                  })} target="_blank" href={this.props.data.field_buy_online_link.uri}>Buy Online</a>
                </div>

                <div className="retailer-item">
                  <div className="retailer-item_image" />
                  <a className="button blue-outline" onClick={() => sendEvent({
                    ga: {
                      category: 'Buy Online',
                      action: 'Click',
                      label: 'Walmart_CTA'
                    }
                  })} href="https://www.walmart.com/tp/scotties" target="_blank">Shop Online</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default BuyOnline
